import React from 'react';
import "../Layouts/test.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Aboutus from "../images/aboutus.jpg";

import Conc from './Conc';


import {
  BrowserRouter as Router,
  Route, Link, Switch
} from "react-router-dom";

class About extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div>
        <header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Gurukrupa Housekeeping & Services</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>



        <section id="about" class="about">
          <div class="container">

            <div class="row">
              <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                <img src="assets/img/about.jpg" class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                <h3>About us.</h3>
                <p class="fst-italic">
                  This Company is One of the Largest Home Services Platform , Launched in 2021.
                </p>
                <ul>
                  <li><i class="bi bi-check-circle"></i>The Platform helps customer book reliable & high quality Services.  </li>
                  <li><i class="bi bi-check-circle"></i>Services Delivered by Trained Professionals  </li>
                  <li><i class="bi bi-check-circle"></i> Company's Vision is to empower millions of Professionals Worldwide </li>
                </ul>

              </div>
            </div>

          </div>
        </section>

















        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">

                <div class="col-lg-4 col-md-6">
                  <div class="footer-info">
                    <h3>Gurukrupa Housekeeping & Services</h3>
                    <p>
                    Chintamani Park Manjari B.K Pune  <br></br>
                      <strong>Phone:</strong> +91 18002026161<br></br>
                      <strong>Email:</strong> guruservices@gmail.com<br></br>
                    </p>
                    <div class="social-links mt-3">
                      <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                      <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                      <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                      <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="/Disclaimer">Disclaimer Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/refund">Security Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/Efund">Refund Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/terms">Terms and conditions</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/privacy">Privacy policy</a></li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Full Home Cleaning </a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Household Services</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Cleaning and Pest Control</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Sofa And Carpet Cleaning</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Support</a></li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>Pune</p>
                  <form action="" method="post">
                    <input type="email" name="email" /><input type="submit" value="Subscribe" />
                  </form>

                </div>

              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">
              &copy; Copyright <strong><span>Day</span></strong>. All Rights Reserved
            </div>
            <div class="credits">

              Designed by <a href="https://bootstrapmade.com/">Gurukrupa Housekeeping & Services</a>
            </div>
          </div>
        </footer>









      </div>
    )
  }
}

export default About;