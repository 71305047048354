import React from 'react';
import "../Layouts/test.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';





class Test extends React.Component {


  render() {
    return (
      <div>
        <header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Gurukrupa Housekeeping & Services</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>



        <section id="hero" className="hero d-flex align-items-center">

          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <h1 data-aos="fade-up">Housekeeping & Services</h1>
                <h2 data-aos="fade-up" data-aos-delay="400"></h2>
              </div>
              <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                <img src="assets/img/hero-img.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>

        </section>


        <section id="about" class="about">
          <div class="container">

            <div class="row">
              <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                <img src="assets/img/about.jpg" class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                <h3>About us.</h3>
                <p class="fst-italic">
                  This Company is One of the Largest Home Services Platform , Launched in 2021.
                </p>
                <ul>
                  <li><i class="bi bi-check-circle"></i>The Platform helps customer book reliable & high quality Services.  </li>
                  <li><i class="bi bi-check-circle"></i>Services Delivered by Trained Professionals  </li>
                  <li><i class="bi bi-check-circle"></i> Company's Vision is to empower millions of Professionals Worldwide </li>
                </ul>

              </div>
            </div>

          </div>
        </section>


        <section id="services" class="services">
          <div class="container">

            <div class="section-title">
              <span>Services</span>
              <h2>Services</h2>
              <p>Services</p>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                <div class="icon-box">
                  <div class="icon"><i class="bx bxl-dribbble"></i></div>
                  <h4><a href="">Full Home Cleaning </a></h4>
                  <p>Trained Professionals for the Services with proper Precautions.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="150">
                <div class="icon-box">
                  <div class="icon"><i class="bx bx-file"></i></div>
                  <h4><a href="">Household Services</a></h4>
                  <p>User in need, Household Service Providers at door in the time of need .</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                <div class="icon-box">
                  <div class="icon"><i class="bx bx-tachometer"></i></div>
                  <h4><a href="">Cleaning and Pest Control</a></h4>
                  <p>Effective Medicines.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="450">
                <div class="icon-box">
                  <div class="icon"><i class="bx bx-world"></i></div>
                  <h4><a href="">Sofa And Carpet Cleaning </a></h4>
                  <p>3 types of different Methods for Effective Cleaning.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="600">
                <div class="icon-box">
                  <div class="icon"><i class="bx bx-slideshow"></i></div>
                  <h4><a href="">Support</a></h4>
                  <p> Make life easier.Connect Easily with Trained Professionals Support 24x 7</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="fade-up" data-aos-delay="750">
                <div class="icon-box">
                  <div class="icon"><i class="bx bx-arch"></i></div>
                  <h4><a href="">Appliances Repairing and massaging Services </a></h4>
                  <p>Extra features at low prices</p>
                </div>
              </div>

            </div>

          </div>
        </section>

        <section id="cta" class="cta">
          <div class="container" data-aos="zoom-in">

            <div class="row">
              <div class="col-lg-9 text-center text-lg-start">
                <h3>Call To Action</h3>
                <p> Support 24 x 7</p>
              </div>
              <div class="col-lg-3 cta-btn-container text-center">
                <a class="cta-btn align-middle" href="#">Call To Action</a>
              </div>
            </div>

          </div>
        </section>

        <section id="pricing" class="pricing">
          <div class="container">

            <div class="section-title">
              <span>Pricing</span>
              <h2>Pricing</h2>
              <p>Our Platform Charges</p>
            </div>

            <div class="row">

              <div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="150">
                <div class="box">
                  <h3>Free</h3>
                  <h4><sup>Rs</sup>0<span> / month</span></h4>

                  <div class="btn-wrap">
                    <a href="#" class="btn-buy">Buy Now</a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mt-4 mt-md-0" data-aos="zoom-in">
                <div class="box featured">
                  <h3>Gold</h3>
                  <h4><sup>Rs</sup>199<span> / month</span></h4>

                  <div class="btn-wrap">
                    <a href="#" class="btn-buy">Buy Now</a>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
                <div class="box">
                  <h3>Prime</h3>
                  <h4><sup>Rs</sup>399<span> / month</span></h4>

                  <div class="btn-wrap">
                    <a href="#" class="btn-buy">Buy Now</a>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>


        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">

                <div class="col-lg-4 col-md-6">
                  <div class="footer-info">
                    <h3>Gurukrupa Housekeeping & Services</h3>
                    <p>
                    Chintamani Park Manjari B.K Pune  <br></br>
                      <strong>Phone:</strong> +91 18002026161<br></br>
                      <strong>Email:</strong> guruservices@gmail.com<br></br>
                    </p>
                    <div class="social-links mt-3">
                      <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                      <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                      <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                      <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="/Disclaimer">Disclaimer Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/refund">Security Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/Efund">Refund Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/terms">Terms and conditions</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/privacy">Privacy policy</a></li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Full Home Cleaning </a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Household Services</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Cleaning and Pest Control</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Sofa And Carpet Cleaning</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Support</a></li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>Pune</p>
                  <form action="" method="post">
                    <input type="email" name="email" /><input type="submit" value="Subscribe" />
                  </form>

                </div>

              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">
              &copy; Copyright <strong><span>Day</span></strong>. All Rights Reserved
            </div>
            <div class="credits">

              Designed by <a href="https://bootstrapmade.com/">Gurukrupa Housekeeping & Services</a>
            </div>
          </div>
        </footer>
















      </div>
    )
  }
}

export default Test;