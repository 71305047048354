import React from 'react';
import "../Layouts/test.css";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import {
  BrowserRouter as Router,
  Route, Link, Switch
} from "react-router-dom";

class Test extends React.Component {


  render() {
    return (
      <div>

        <header className="page-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand href="#home">Gurukrupa Housekeeping & Services</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact us</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>

            </Nav>
            <Form inline>
              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar>
        </header>


        <section id="details" class="details">
          <div class="container">

            <div class="row content">
              <div class="col-md-4 aos-init aos-animate" data-aos="fade-right">
                <img src="assets/img/details-1.png" class="img-fluid" alt="" />
              </div>
              <div class="col-md-8 pt-4 aos-init aos-animate" data-aos="fade-up">
                <h3>Privacy Policy</h3>
                <p class="fst-italic">
                  This privacy policy sets out how Gurukrupa Housekeeping & Services uses and protects any information that you give Gurukrupa Housekeeping & Services when you use this website.
                  Gurukrupa Housekeeping & Services is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
                  Gurukrupa Housekeeping & Services may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from [13-02-2021].
                </p>
                <ul>
                  <li><i class="bi bi-check"></i> We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</li>
                  <li><i class="bi bi-check"></i>  Internal record keeping</li>
                  <li><i class="bi bi-check"></i>  We may use the information to improve our products and services.</li>
                  <li><i class="bi bi-check"></i>   We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                </ul>
                <p>
                  From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.
                </p>

              </div>
            </div>






          </div>
        </section>


        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">

                <div class="col-lg-4 col-md-6">
                  <div class="footer-info">
                    <h3>Gurukrupa Housekeeping & Services</h3>
                    <p>
                    Chintamani Park Manjari B.K Pune  <br></br>
                      <strong>Phone:</strong> +91 18002026161<br></br>
                      <strong>Email:</strong> guruservices@gmail.com<br></br>
                    </p>
                    <div class="social-links mt-3">
                      <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                      <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                      <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                      <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="/Disclaimer">Disclaimer Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/refund">Security Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/Efund">Refund Policies</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/terms">Terms and conditions</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="/privacy">Privacy policy</a></li>
                  </ul>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Full Home Cleaning </a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Household Services</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Cleaning and Pest Control</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Sofa And Carpet Cleaning</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#">Support</a></li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>Pune</p>
                  <form action="" method="post">
                    <input type="email" name="email" /><input type="submit" value="Subscribe" />
                  </form>

                </div>

              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">
              &copy; Copyright <strong><span>Day</span></strong>. All Rights Reserved
            </div>
            <div class="credits">

              Designed by <a href="https://bootstrapmade.com/">Gurukrupa Housekeeping & Services</a>
            </div>
          </div>
        </footer>


      </div>
    )
  }
}

export default Test;